import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
export const pageQuery = graphql`
  {
    allPasteLibraries(filter: {name: {eq: "@twilio-paste/uid-library"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/libraries/uid-library/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="UID Library" categoryRoute={SidebarCategoryRoutes.LIBRARIES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-libraries/uid" storybookUrl="/?path=/story/libraries-uid" data={props.data.allPasteLibraries.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`Why do we need unique IDs`}</h3>
        <p>{`Unique IDs are important because:`}</p>
        <ul>
          <li parentName="ul">{`The `}<a parentName="li" {...{
              "href": "https://html.spec.whatwg.org/multipage/dom.html#the-id-attribute"
            }}>{`HTML spec says they should be unique`}</a>{` and strange bugs might occur otherwise.`}</li>
          <li parentName="ul">{`You need them to correctly pair form elements together for better UX.`}</li>
          <li parentName="ul">{`You need them to provide additional information for screenreaders, such as to provide a text hint to icons.`}</li>
        </ul>
        <p>{`Here's what could happen without unique IDs:`}</p>
        <iframe src="https://codesandbox.io/embed/blue-http-xqqo4?fontsize=14&hidenavigation=1&theme=dark" style={{
          "width": "100%",
          "height": "310px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} title="blue-http-xqqo4" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
        <br />
        <br />
        <br />
        <h3>{`Why do we need a library?`}</h3>
        <p>{`Anytime a software product needs unique identifiers, the likelyhood of `}<inlineCode parentName="p">{`id`}</inlineCode>{` collisions increases the larger the project and team becomes.
Furthermore, the way React has transformed the traditional process of page-based development to componened-based development leads to more situations where an `}<inlineCode parentName="p">{`id`}</inlineCode>{` can clash.
For example, if one page renders the same `}<inlineCode parentName="p">{`DeleteIcon`}</inlineCode>{` component twice and that icon has `}<inlineCode parentName="p">{`id="delete-icon"`}</inlineCode>{` hardcoded, then we now have an `}<inlineCode parentName="p">{`id`}</inlineCode>{` collision.
It is very difficult to predict the exact circumstances where a component will be used, so any time a component needs an `}<inlineCode parentName="p">{`id`}</inlineCode>{` the risk of a collision is present.`}</p>
        <h3>{`How does this library work?`}</h3>
        <p>{`This library maintains a sequential count, so that everytime a component mounts and needs an ID, it will fetch the next value (`}<inlineCode parentName="p">{`count += 1`}</inlineCode>{`).`}</p>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around `}<a parentName="p" {...{
            "href": "https://github.com/thearnica/react-uid"
          }}>{`react-uid`}</a>{`.
If you’re wondering why we wrapped that package into our own, we reasoned that
it would be best for our consumers’ developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`We can control which APIs we expose and how to expose them. For example, in this package
export only some of the source package's exports.`}</li>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that
depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘@react-uid’`}</inlineCode>{`
to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/uid-library`}</inlineCode>{`,
this refactor can be avoided. The only change would be a version bump in the package.json file.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce
potential bugs our consumers may face.`}</li>
        </ul>
        <p>{`This package has 4 exports, described below.`}</p>
        <h3>{`API`}</h3>
        <h4>{`useUID()`}</h4>
        <p>{`This is a React Hook to generate a UID.`}</p>
        <iframe src="https://codesandbox.io/embed/happy-bush-ued0c?fontsize=14&hidenavigation=1&theme=dark" style={{
          "width": "100%",
          "height": "310px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} title="useUID example" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
        <Box marginTop="space100" mdxType="Box" />
        <h4>{`useUIDSeed()`}</h4>
        <p>{`This React Hook creates a seed from which you can generate several UIDs. It's handy for when you need multiple `}<inlineCode parentName="p">{`id`}</inlineCode>{`s in a single component.`}</p>
        <iframe src="https://codesandbox.io/embed/useuidseed-example-p0sxb?fontsize=14&hidenavigation=1&theme=dark" style={{
          "width": "100%",
          "height": "310px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} title="useUIDSeed example" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
        <Box marginTop="space100" mdxType="Box" />
        <h4>{`UIDFork Component`}</h4>
        <Callout variant="primary" mdxType="Callout">
  <CalloutText mdxType="CalloutText">This is only needed for lazy-loaded or code-split chunks.</CalloutText>
        </Callout>
        <p>{`This React Component pivots the global singleton `}<inlineCode parentName="p">{`count`}</inlineCode>{` to read a counter from React Context.
If your app has code-splitting or lazy-loading, you want to wrap every entrypoint with `}<inlineCode parentName="p">{`<UIDFork>`}</inlineCode>{` in order
to garantee there isn't any collisions.`}</p>
        <Box marginTop="space100" mdxType="Box" />
        <h4>{`uid(item, index)`}</h4>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Don't use this API in React Components</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Prefer using the other React Hooks (useUID or useUIDSeed) in React components. They are Server Side Rendering (SSR)
    compatible and the recommended future-proof API.
  </CalloutText>
        </Callout>
        <p>{`A plain Javascript function to return a UID. `}<inlineCode parentName="p">{`item`}</inlineCode>{` should be an object (in JS, this could mean a function, an array, etc.). If `}<inlineCode parentName="p">{`item`}</inlineCode>{` isn't an object
, provide the second argument `}<inlineCode parentName="p">{`index`}</inlineCode>{`. This API is not SSR friendly.`}</p>
        <p>{`We provide this export for quick UID generation in utility files.`}</p>
        <iframe src="https://codesandbox.io/embed/uid-example-s35fo?fontsize=14&hidenavigation=1&theme=dark" style={{
          "width": "100%",
          "height": "500px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} title="uid Example" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      